import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TeamAccountSlice } from './types';

const initialState: TeamAccountSlice = {
  id: '',
  teamName: '',
  aboutTeam: '',
};

const teamAccountSlice = createSlice({
  name: 'teamAccount',
  initialState,
  reducers: {
    updateTeamAccount: (state, action: PayloadAction<TeamAccountSlice>) => {
      return { ...state, ...action.payload };
    },
    resetAccount: () => initialState,
  },
});

export const { updateTeamAccount, resetAccount } = teamAccountSlice.actions;

export default teamAccountSlice.reducer;
