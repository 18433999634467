import { createStyles } from '@mantine/core';

const HEADER_HEIGHT = 90;

export const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },

    a: {
      color: theme.colors.gray[7],
      fontSize: '1.25rem',
      textDecoration: 'none',
      fontWeight: 600,
      padding: '0 15px',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
  hideOnMobile: {
    display: 'block',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  nestedLinks: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      color: theme.colors.gray[8],
    },
  },
  primaryLink: {
    position: 'relative',
    fontWeight: 600,
    '&:hover': {
      color: theme.colors.gray[9],
      '&:after': {
        content: '"  "',
        position: 'absolute',
        bottom: '-5px',
        left: 0,
        height: '3px',
        width: '100%',
        backgroundColor: theme.colors.yellow[3],
      },
    },
  },
}));
