import { NavigationItem } from 'types/cms';
import { Accordion, Container, Grid, Group, NavLink, SimpleGrid, Text, Title } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import fundraiseRegulator from '@/public/fundraising-regulator.webp';
import isbLogo from '@/public/Islamic_Scholar_Board_Logo_Black.png';
import Image from 'next/image';
import { useStyles } from './styles';

type FooterProps = {
  nav: NavigationItem[];
};

const Footer = ({ nav }: FooterProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.footer}>
      <Container size="lg">
        <Accordion defaultValue="customization" className={classes.item}>
          <Grid>
            {nav.map((firstLevel: NavigationItem) => (
              <Grid.Col key={firstLevel.id} md={4}>
                <NavLink label={firstLevel.title}>
                  {firstLevel.items?.map((item) => {
                    return (
                      <Link
                        key={item?.id}
                        href={item?.path || '/'}
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          width: '100%',
                          display: 'block',
                        }}
                      >
                        {item?.title}
                      </Link>
                    );
                  })}
                </NavLink>
              </Grid.Col>
            ))}

            <Grid.Col md={4}>
              <NavLink label="BANK DETAILS">
                <Text>Account Name: Human Aid & Advocacy</Text>
                <Text>Sort Code: 30-00-83</Text>
                <Text>Account Number: 01275801</Text>
                <Text>IBAN: GB20ARAY30008301275801</Text>
              </NavLink>
            </Grid.Col>
            <Grid.Col xs={12} order={1}>
              <Group ml="1rem">
                <Text>
                  © Copyright 2023 Human Aid & Advocacy. All rights reserved. Registered Charity No.
                  1138111
                </Text>
              </Group>
            </Grid.Col>

            <Grid.Col md={4}>
              <Title
                weight={900}
                size={'1.25rem'}
                className={classes.hideOnMobile}
                mb="1rem"
                ml="0.7rem"
                order={4}
              >
                FOLLOW US
              </Title>
              {/* <Text
                // size="xl"

              >
              </Text> */}
              <Group ml="1rem">
                <Link href="https://www.instagram.com/humanaidadvocacy" target="_blank">
                  <BsInstagram color="black" size="1.5rem" />
                </Link>
                <Link href="https://www.facebook.com/humanaidadvocacy" target="_blank">
                  <BsFacebook color="black" size="1.5rem" />
                </Link>
                <Link href="https://twitter.com/humanaidadvoc" target="_blank">
                  <BsTwitter color="black" size="1.5rem" />
                </Link>
                <Link href="https://www.youtube.com/@humanaidadvocacy" target="_blank">
                  <BsYoutube color="black" size="1.5rem" />
                </Link>
                <Link href="https://www.linkedin.com/company/humanaidadvocacy/" target="_blank">
                  <BsLinkedin color="black" size="1.5rem" />
                </Link>
              </Group>
            </Grid.Col>
            <Grid.Col md={4}>
              <div className={classes.headOffice}>
                <Text size="lg" color="black.8" fw="bold" mb="0.5rem" ml="1rem">
                  Head Office
                </Text>
                <Text className={classes.addressText}>
                  <FaMapMarkerAlt /> 3 Coke Street, London, E1 1ER
                </Text>
                <Text className={classes.addressText}>
                  <GrMail /> info@humanaid.org
                </Text>
                <Text className={classes.addressText}>
                  <FaPhoneAlt /> +44 (0)20 3875 0563
                </Text>
                <div className={classes.headOffice}>
                  <Text size="lg" color="black.8" fw="bold" mb="0.5rem" ml="1rem" mt={10}>
                    Manchester Office
                  </Text>
                  <Text className={classes.addressText}>
                    <FaMapMarkerAlt /> 22a Clegg Street, Oldham, OL1 1PL
                  </Text>
                </div>
              </div>
            </Grid.Col>
            <Grid.Col xs={12} order={3}>
              <Group>
                <Image src={isbLogo} alt="ISB Logo" width={100} />
                <Image src={fundraiseRegulator} width={180} alt="Fundraising Regulator Logo" />
              </Group>
            </Grid.Col>
          </Grid>
        </Accordion>
      </Container>
    </div>
  );
};

export default Footer;
