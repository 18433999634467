/**
 * client side mainly, do not include server stuff
 */
/* eslint-disable max-lines */
import { Stripe, loadStripe } from '@stripe/stripe-js';
import short from 'short-uuid';
import {
  Enum_Componentdonationquickdonateoptions_Frequency,
  ComponentDonationQuickDonateOptions,
  Enum_Componentdonationquickdonateoptions_Type,
  Donation,
  DonationEntity,
} from 'types/cms';
import { differenceInMinutes, format, fromUnixTime, parseISO } from 'date-fns';
import { StripeDonation } from '../redux/slices/types';

type displayNameForFreq = {
  [key: string]: string;
};
let initialized = false;

/**
 * Log debug messages in console.
 *
 * @param message
 */
export function debug(message: string | null) {
  if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_DEBUG !== 'true') {
    return;
  }

  if (!initialized) {
    console.info('------------------------------------');
    console.info('Facebook Conversion API Debugging');
    console.info('------------------------------------');
  }

  initialized = true;

  if (message) console.info(message);
}

export function percentageInc(total: number, per: number) {
  return total + (per / 100) * total;
}

export function reversePercentage(finalNum: number, per: number) {
  return (finalNum * 100) / (per + 100);
}

export function dateFutureByMins(from: string, to = new Date()) {
  // is first date after second? return 1 else -1
  return differenceInMinutes(to, parseISO(from)) > 0;
}

export function dateFormat(datetime: string, formatString = 'E, d MMM, HH:mm') {
  return format(parseISO(datetime), formatString);
}

export function timestampFormat(datetime: number, formatString = 'E d MMM yy, HH:mm') {
  return format(fromUnixTime(datetime), formatString);
}
export const formatToCurrency = (num: number | string) =>
  num &&
  new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }).format(Number(num));

export function isLocalStorageAvailable() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
export const displayNameForFrequency: Partial<displayNameForFreq> = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Yearly',
};

export const checkIfIntervalsMix = (data: StripeDonation[]) => {
  const intervalTypes = ['day', 'week', 'month', 'year'];
  const totalIntervals: string[] = [];
  data &&
    data.forEach((donation) => {
      if (!totalIntervals.includes(donation.interval)) {
        if (intervalTypes.includes(donation.interval)) totalIntervals.push(donation.interval);
      }
    });

  return totalIntervals.length >= 2;
};

export const getRegularOptionList = (regularDonateOptions: any[]): string[] => {
  return regularDonateOptions
    .map((option) => option.frequency)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    });
};

export const getDisplayTextFreqList = (
  freqList: string[]
):
  | Array<{ value: string; label: string }>
  | Array<{ value: Enum_Componentdonationquickdonateoptions_Frequency; label: string }> => {
  return freqList.map((el) => ({ value: el, label: displayNameForFrequency[el]! }));
};

//  expecting the current rendered donateOptionsView here
export const filterByOtherAmount = (data: ComponentDonationQuickDonateOptions[]) =>
  data.filter(
    (donation: ComponentDonationQuickDonateOptions) =>
      donation.type === Enum_Componentdonationquickdonateoptions_Type.Variable
  );

export const filterBySingle = (data: ComponentDonationQuickDonateOptions[]) =>
  data.filter(
    (donation: ComponentDonationQuickDonateOptions) =>
      donation.frequency === Enum_Componentdonationquickdonateoptions_Frequency.Single
  );

export const filterByRegular = (data: ComponentDonationQuickDonateOptions[]) =>
  data.filter(
    (donation: ComponentDonationQuickDonateOptions) =>
      donation.frequency !== Enum_Componentdonationquickdonateoptions_Frequency.Single
  );

export const filterByFreqName = (
  data: ComponentDonationQuickDonateOptions[],
  freq: string | null
) => data.filter((donation: ComponentDonationQuickDonateOptions) => donation.frequency === freq);

export const getTotalDonations = (data: any) => {
  return data.reduce((acc: any, curr: StripeDonation) => {
    return acc + curr.amount * (curr.quantity || 1);
  }, 0);
};

export const getTotalDonationsCMS = (data: any) => {
  return data
    .filter((donation: DonationEntity) => !donation.attributes?.offline)
    .reduce((acc: any, curr: { attributes: any }) => {
      return acc + curr.attributes.amount;
    }, 0);
};
export const getTotalOfflineDonationsCMS = (data: any) => {
  return data
    .filter((donation: DonationEntity) => donation.attributes?.offline)
    .reduce((acc: any, curr: { attributes: any }) => {
      return acc + curr.attributes.amount;
    }, 0);
};

export const getTotalZakahDonationsCMS = (data: any) => {
  return data
    .filter((donation: DonationEntity) => donation.attributes?.intention === 'Zakat')
    .reduce((acc: any, curr: { attributes: any }) => {
      return acc + curr.attributes.amount;
    }, 0);
};

export const getTotalDonationsCMSMonthly = (data: any) => {
  return data
    .filter((el: any) => el.attributes.interval === 'month')
    .reduce((acc: any, curr: { attributes: any }) => {
      return acc + curr.attributes.amount;
    }, 0);
};
export const getTotalGiftAided = (data: any) => {
  return data
    .filter((el: any) => el.attributes.giftaid)
    .reduce((acc: any, curr: { attributes: any }) => {
      return acc + curr.attributes.amount;
    }, 0);
};

export const getUniqueDonorsByEmail = (data: any) => {
  return data.reduce((acc: any, curr: { attributes: any }) => {
    return acc.includes(curr.attributes.email) ? acc : [...acc, curr.attributes.email];
  }, []);
};

export const generateId = () => {
  return short.generate();
};

let stripePromise: Promise<Stripe | null>;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISH!);
  }
  return stripePromise;
};
