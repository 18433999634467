import { MantineThemeOverride, ButtonProps } from '@mantine/core';
import { Montserrat } from 'next/font/google';

const montserrat = Montserrat({ preload: true, subsets: ['latin'] });

const ButtonDefaultProps: Partial<ButtonProps> = {};

const theme: MantineThemeOverride = {
  fontFamily: `${montserrat.style.fontFamily}, sans serif`,
  headings: {
    fontFamily: `${montserrat.style.fontFamily}, sans serif`,
    sizes: {
      h1: { fontSize: 34, fontWeight: 700 },
      h2: { fontSize: 18, fontWeight: 700 },
      h3: { fontSize: 20, fontWeight: 600 },
      h4: { fontSize: 16, fontWeight: 400 },
      h5: { fontSize: 12, fontWeight: 400 },
      h6: { fontSize: 10, fontWeight: 400 },
    },
  },
  white: 'rgb(246, 246, 249)',
  black: 'rgb(50, 50, 77)',
  primaryColor: 'yellow',
  primaryShade: 5,
  defaultGradient: { from: 'yellow', to: 'red', deg: 45 },
  colors: {
    yellow: [
      '#FFFBE7',
      '#FFF6CD',
      '#FFEC9D',
      '#FDE36A',
      '#FFD93A', // primary shade
      '#FED007',
      '#FABF0C',
      '#F5AC13',
      '#F19B19',
      '#EC891E',
    ],
    black: [
      '#E8E7E7',
      '#CFCDCD',
      '#B8B6B6',
      '#A09D9D',
      '#888484',
      '#706B6B',
      '#595353',
      '#413A3A',
      '#292222',
      '#110909',
    ],
    red: [
      '#FBE9EC',
      '#F6D2D9',
      '#F2BCC6',
      '#EDA5B3',
      '#E98FA0',
      '#E5798D',
      '#E1637A',
      '#DC4C67',
      '#D83654',
      '#D31F41', // secondary main shade
    ],
    grey: [
      '#F8F9FA',
      '#F1F3F5',
      '#E9ECEF',
      '#DEE2E6',
      '#CED4DA',
      '#ADB5BD',
      '#868E96',
      '#495057',
      '#343a40',
      '#252A2D',
    ],
  },
};

export default theme;
