import { ActionIcon, Menu } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React from 'react';
import { MdLogin, MdLogout, MdPersonOutline } from 'react-icons/md';

type Props = {};

const ProfileMenu = (props: Props) => {
  const router = useRouter();
  const { data } = useSession();

  return (
    <div>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <ActionIcon>
            <MdPersonOutline />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          {!data && (
            <Menu.Item icon={<MdLogin size={14} />} onClick={() => router.push('/account/login')}>
              Login
            </Menu.Item>
          )}

          {data && (
            <>
              <Menu.Item
                icon={<MdLogout size={14} />}
                onClick={() => {
                  showNotification({
                    title: 'Account',
                    message: 'You have been logged out!',
                    color: 'green',
                  });
                  signOut({ callbackUrl: '/' });
                }}
              >
                Log out
              </Menu.Item>
              <Menu.Item icon={<MdLogout size={14} />} onClick={() => router.push('/dashboard')}>
                Dashboard
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
