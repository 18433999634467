import React, { useState } from 'react';
import { Checkbox, Group, ScrollArea, Text } from '@mantine/core';

import { addZakahUplift, toggleCrossSell } from '@/redux/slices/donationSlice';
import { useStyles } from './styles';

import { formatToCurrency, getTotalDonations } from '../../utils/helpers';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import BasketItem from './BasketItem';
import { EnumIntention, StripeDonation } from '../../redux/slices/types';

type Props = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckoutBasket = (props: Props) => {
  const { classes } = useStyles();
  const { donations, hasSadakahUplift, hasZakahUplift } = useAppSelector((state) => state.donation);
  const dispatch = useAppDispatch();

  const hasZakah =
    donations.filter((donation) => donation.intention === EnumIntention.Zakat).length > 0;
  const hasSadakah =
    donations.filter((donation) => donation.intention === EnumIntention.Sadaqa).length > 0;

  const handleCrossSell = (event: React.ChangeEvent<HTMLInputElement>) => {
    const toggle = event.currentTarget.checked;
    dispatch(toggleCrossSell(toggle));
  };

  const handleZakahUplift = (event: React.ChangeEvent<HTMLInputElement>) => {
    const toggle = event.currentTarget.checked;
    dispatch(addZakahUplift(toggle));
  };

  return (
    <>
      <ScrollArea.Autosize maxHeight="30vh" type="auto" mb={5}>
        {donations &&
          donations.length > 0 &&
          donations.map((data: JSX.IntrinsicAttributes & StripeDonation) => (
            <BasketItem key={data.id} {...data} />
          ))}
      </ScrollArea.Autosize>
      {hasSadakah && (
        <Group position="apart" className={classes.help_us_box}>
          <Text>I&apos;d like to help cover processing fees and marketing cost!</Text>
          <Checkbox
            checked={hasSadakahUplift}
            onChange={handleCrossSell}
            label="YES ADD £5 SADAQAH"
            description="Cover processing fees for your donation and costs related to marketing so we can reach more people with the campaign inshaAllah."
          />
        </Group>
      )}
      {hasZakah && (
        <Group position="apart" className={classes.help_us_box}>
          <Checkbox
            checked={hasZakahUplift}
            onChange={handleZakahUplift}
            label="I want to add 12.5% sadaqah to cover costs relating to the distribution of my Zakah"
          />
        </Group>
      )}
      <Group position="apart" className={classes.help_us_box}>
        <Text>Total</Text>
        <Text>
          {donations && donations.length > 0
            ? formatToCurrency(getTotalDonations(donations))
            : formatToCurrency(0)}
        </Text>
      </Group>
    </>
  );
};

export default CheckoutBasket;
