import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  donation_wrapper: {
    background: '#F1F3F5',
    padding: '0.5rem',
    marginBottom: '1rem',
  },
  align_end: {
    display: 'flex',
    justifyContent: 'end',
  },
  appeal_box: {
    display: 'flex',
  },
  appeal_text: {
    flex: 1,
    color: '#8E8E8E',
    fontWeight: 'bold',
  },
  help_us_box: {
    background: '#F1F3F5',
    padding: '0.5rem',
    marginBottom: '1rem',
  },
}));
