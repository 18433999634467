import { Button, Alert, Modal } from '@mantine/core';
import Link from 'next/link';
import { StripeDonation } from '@/redux/slices/types';
import CheckoutBasket from '../../../components/CheckoutBasket';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';

import { setIsSidebarOpen } from '../../../redux/slices/donationSlice';
import { checkIfIntervalsMix } from '../../../utils/helpers';
import { fbEvent } from '@/components/Facebook/conversion-api';

const CheckoutDrawer = () => {
  const isSidebarOpen = useAppSelector((state) => state.donation.sidebar);
  const { donations } = useAppSelector((state) => state.donation);
  const dispatch = useAppDispatch();

  const futureItems = donations.filter((donation: StripeDonation) => donation.startDate).length;

  const isProblem = donations && donations.length > 0 && checkIfIntervalsMix(donations);

  const isFutureMixedWithOthers = futureItems === 1 ? donations.length > 1 : false;

  const hasNone = donations && donations.length === 0;

  return (
    <Modal
      padding="sm"
      overflow="inside"
      opened={isSidebarOpen}
      onClose={() => dispatch(setIsSidebarOpen(false))}
      title="Your Donations"
    >
      {isProblem ? (
        <Alert title="Error!" color="red">
          You cannot mix daily, weekly, monthly or yearly donation frequencies currently. Please
          remove one.
        </Alert>
      ) : null}

      {futureItems >= 2 ? (
        <Alert title="Error!" color="red">
          Currently you can only checkout with one regular subscription which starts in the future.
          Please remove one.
        </Alert>
      ) : null}
      {isFutureMixedWithOthers ? (
        <Alert title="Error!" color="red">
          You cannot checkout a regular subscription which starts in the future with a one time
          donation. Please remove one.
        </Alert>
      ) : null}
      <CheckoutBasket />
      <Link href="/account/guest" passHref legacyBehavior>
        <Button
          fullWidth
          color="dark"
          size="xl"
          component="a"
          onClick={() => {
            fbEvent({
              eventName: 'InitiateCheckout',
              products: donations,
              enableStandardPixel: true,
            });
            dispatch(setIsSidebarOpen(false));
          }}
          disabled={isProblem || hasNone || isFutureMixedWithOthers || futureItems >= 2}
        >
          Checkout
        </Button>
      </Link>
      {/* </ScrollArea> */}
      {/* </Drawer> */}
    </Modal>
  );
};

export default CheckoutDrawer;
