import { NavigationItem } from 'types/cms';
import Footer from '@/components/Footer';
import ResponsiveHeader from '@/components/ResponsiveHeader';
// import { isLocalStorageAvailable } from '@/utils/helpers';
import { LoadingOverlay } from '@mantine/core';
import React, { useEffect } from 'react';
import CheckoutDrawer from '../checkout/CheckoutDrawer';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { updateUser } from '@/redux/slices/userSlice';
import { useRouter } from 'next/router';

type LayoutProps = {
  children: JSX.Element;
  headerNav: NavigationItem[];
  footerNav: NavigationItem[];
  loading: boolean;
};

const Layout = ({ headerNav, footerNav, loading, children }: LayoutProps) => {
  // const [isModalVisible, setModalVisible] = useState(false);
  const router = useRouter();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !user.sessionSrc &&
      !user.sessionMedium &&
      !user.sessionCampaign &&
      !user.sessionRef &&
      !user.sessionContent
    ) {
      dispatch(
        updateUser({
          sessionSrc: router.query.utm_source as string,
          sessionMedium: router.query.utm_medium as string,
          sessionCampaign: router.query.utm_campaign as string,
          sessionRef: router.query.ref as string,
          sessionContent: router.query.utm_content as string,
        })
      );
    }
    if (!user.sessionLandingPage) {
      dispatch(
        updateUser({
          sessionLandingPage: window.location.pathname,
        })
      );
    }
    // if (isLocalStorageAvailable()) {
    //   const showPopup = localStorage.getItem('showpopup');
    //   if (!showPopup || showPopup?.length === 1) {
    //     setModalVisible(false);
    //   }
    // } else {
    //   setModalVisible(false);
    // }
  }, [router.query]);

  return (
    <div>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      {/* <AnnouncementModal onCloseFunc={setModalVisible} visible={isModalVisible} /> */}
      <ResponsiveHeader nav={headerNav} />
      {/* <Container bg="#1b223600" size="lg" px={0} py={10}> */}
      {/* <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
          <Link href="/appeals/zakah">
            <Img
              onClick={() => {
                event('bannerZakahClick', { category: 'banner clicks', label: 'ZAKAH' });
              }}
              src="/ISB_Banner_wide.jpg"
              alt="banner"
              withPlaceholder
            />
          </Link>
        </MediaQuery> */}
      {/* <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Link href="/appeals/zakah">
            <Img
              onClick={() => {
                event('bannerZakahClick', { category: 'banner clicks', label: 'ZAKAH' });
              }}
              src="/ISB_Banner_mobile.jpg"
              alt="banner"
              withPlaceholder
            />
          </Link>
        </MediaQuery> */}
      {/* </Container> */}

      <CheckoutDrawer />
      {children}
      <Footer nav={footerNav} />
    </div>
  );
};

export default Layout;
