import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CreateFundraiserType } from './types';

const initialState: CreateFundraiserType = {
  fullName: '',
  country: '',
  appeal: '',
  fundraiserName: '',
  teamName: '',
  goal: 1250,
  teamMemberGoal: 0,
  endDate: '',
  yourStory: '',
};

const fundraiserSlice = createSlice({
  name: 'fundraiser',
  initialState,
  reducers: {
    updateFundraiser: (state, action: PayloadAction<CreateFundraiserType>) => {
      return { ...state, ...action.payload };
    },
    resetFundraiser: () => initialState,
  },
});

export const { updateFundraiser, resetFundraiser } = fundraiserSlice.actions;

export default fundraiserSlice.reducer;
