import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Enum_Componentdonationquickdonateoptions_Frequency } from 'types/cms';
import { generateId } from '@/utils/helpers';
import { FundraiserCheckoutType } from './types';

const initialState: FundraiserCheckoutType = {
  appealName: '',
  donorName: '',
  zakatable: false,
  amount: 0,
  message: '',
  includeName: true,
  includeDonationAmount: true,
  fId: '',
  sfProjectId: '',
  sfVariationId: '',
  sfVariationIdMonth: '',
  consent3rdParty: false,
  campaignName: '',
};

const fundraiserCheckoutSlice = createSlice({
  name: 'fundraiser',
  initialState,
  reducers: {
    updateFundraiserCheckout: (state, action: PayloadAction<FundraiserCheckoutType>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateFundraiserCheckout } = fundraiserCheckoutSlice.actions;

export default fundraiserCheckoutSlice.reducer;
