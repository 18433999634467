import { createSlice } from '@reduxjs/toolkit';
import { AppealEntity } from 'types/cms';

interface AppealTemp {
  appeals: AppealEntity[];
}

const initialState: AppealTemp = {
  appeals: [],
};

const appealsSlice = createSlice({
  name: 'appeals',
  initialState,
  reducers: {
    updateAppeals: (state, action) => {
      state.appeals = action.payload;
    },
  },
});

export const { updateAppeals } = appealsSlice.actions;

export default appealsSlice.reducer;
