import { formatToCurrency } from '@/utils/helpers';
import { Text, ActionIcon, Box } from '@mantine/core';
import { IoMdTrash } from 'react-icons/io';
import { useAppDispatch } from '../../redux/hooks';
import { removeDonation } from '../../redux/slices/donationSlice';
import { StripeDonation } from '../../redux/slices/types';
import { useStyles } from './styles';

const BasketItem = ({ interval, amount, intention, name, quantity, id }: StripeDonation) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  return (
    <div className={classes.donation_wrapper}>
      <div className={classes.appeal_box}>
        <div className={classes.appeal_text}>
          <Text>{name}</Text>
          <Text>{intention}</Text>
          <Text>{interval}</Text>
        </div>
        <Box sx={{ maxWidth: '25%' }}>
          <Text weight={800}>
            {quantity} x {formatToCurrency(amount!)}
          </Text>
        </Box>
      </div>
      <div className={classes.align_end}>
        <ActionIcon onClick={() => dispatch(removeDonation(id))}>
          <IoMdTrash size="30" />
        </ActionIcon>
      </div>
    </div>
  );
};

export default BasketItem;
