import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { SessionProvider } from 'next-auth/react';
import type { Session } from 'next-auth';
import { Provider } from 'react-redux';
import { GET_GLOBAL_CONFIG } from '@/cms/queries';
import { Global, NavigationItem } from 'types/cms';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { useState, useEffect } from 'react';
import store from '@/redux/store';
import theme from '@/assets/mantineTheme';
import Layout from '@/modules/shared/Layout';
import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import '@stripe/stripe-js';

import {
  DefaultOptions,
  HttpLink,
  ApolloLink,
  ApolloClient,
  concat,
  InMemoryCache,
} from '@apollo/client';
import FBPixelScript from '@/components/Facebook/FBPixelScript';
import FBPixelProvider from '@/components/Facebook/FBPixelProvider';

type globalPropsType = {
  metadata: Global;
  headerNav: NavigationItem[];
  footerNav: NavigationItem[];
};
type AppOwnProps = { globalProps: globalPropsType };

let globalPropsCache: globalPropsType;
export default function MyApp({
  globalProps,
  Component,
  pageProps: { session, ...pageProps },
  router,
}: AppProps<{ session: Session }> & AppOwnProps) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    globalPropsCache = globalProps;
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
    };
  }, [router.events]);

  const { metadata, footerNav, headerNav } = globalProps;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <DefaultSeo
        titleTemplate={`%s | ${metadata?.metaTitleSuffix}`}
        title={`${metadata?.metaTitleSuffix}`}
        description={metadata?.meta?.description!}
        openGraph={{
          images:
            metadata &&
            Object.values(metadata?.meta?.shareImage?.data?.attributes?.formats).map(
              (image: any) => {
                return {
                  url: image.url,
                  width: image.width,
                  height: image.height,
                };
              }
            ),
        }}
        twitter={{
          cardType: metadata?.meta?.twitterCardType!,
          handle: metadata?.twitterUsername!,
        }}
      />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}>
        <Provider store={store}>
          <SessionProvider session={session}>
            <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
              <CacheProvider value={cache}>
                <NotificationsProvider limit={5} position="top-right" zIndex={2077}>
                  <GoogleAnalytics trackPageViews />
                  <FBPixelScript />
                  <FBPixelProvider>
                    <Layout loading={loading} headerNav={headerNav} footerNav={footerNav}>
                      <Component {...pageProps} />
                    </Layout>
                  </FBPixelProvider>
                </NotificationsProvider>
              </CacheProvider>
            </MantineProvider>
          </SessionProvider>
        </Provider>
      </GoogleReCaptchaProvider>
    </>
  );
}

MyApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
  context?.ctx?.res?.setHeader('Cache-Control', 'public, s-maxage=119, stale-while-revalidate=239');
  const ctx = await App.getInitialProps(context);

  if (globalPropsCache) {
    return {
      ...ctx,
      globalProps: globalPropsCache,
    };
  }
  if (typeof window === 'undefined') {
    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only', // Used for first execution
        errorPolicy: 'ignore',
      },
    };
    const httpLink = new HttpLink({ uri: `${process.env.STRAPI_URL}/graphql` });
    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          authorization: `Bearer ${process.env.STRAPI_API_KEY}`,
        },
      });
      return forward(operation);
    });

    const apolloCon = new ApolloClient({
      link: concat(authMiddleware, httpLink),
      cache: new InMemoryCache(),
      defaultOptions,
      ssrMode: typeof window === 'undefined',
    });

    const { data } = await apolloCon.query({
      query: GET_GLOBAL_CONFIG,
      variables: {
        footer: 'footer',
        header: 'main-navigation',
      },
    });

    const globalProps = {
      metadata: data.global.data.attributes,
      headerNav: data.header,
      footerNav: data.footer,
    };
    globalPropsCache = globalProps;
    return {
      ...ctx,
      globalProps,
    };
  }
  // @ts-ignore
  return { ...ctx, globalProps: {} };
};
