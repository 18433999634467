import DesktopLogo from '@/public/logo.png';
import { NavigationItem } from 'types/cms';
import { useAppDispatch } from '@/redux/hooks';
import { setIsSidebarOpen } from '@/redux/slices/donationSlice';
import {
  Header,
  Container,
  Group,
  HoverCard,
  UnstyledButton,
  Box,
  ActionIcon,
  Burger,
  Button,
  Drawer,
  Accordion,
  Text,
  SimpleGrid,
} from '@mantine/core';
import { event } from 'nextjs-google-analytics';
import { showNotification } from '@mantine/notifications';
import { useSession, signOut } from 'next-auth/react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { MdOutlineShoppingBasket } from 'react-icons/md';
import ProfileMenu from './ProfileMenu';
import { useStyles } from './styles';

type ResponsiveHeaderProps = {
  nav: NavigationItem[];
};

const ResponsiveHeader: React.FC<ResponsiveHeaderProps> = ({ nav }) => {
  // const [opened, { open, toggle, close }] = useDisclosure(false);
  const [opened, setOpened] = useState(false);
  const [showHeaderCTA, setShowHeaderCTA] = useState(false);

  const { classes } = useStyles();
  const router = useRouter();

  useEffect(() => {
    const hideHeaderCtaCriteria =
      (router.pathname && router.pathname == '/f/[slug]') || router.pathname.includes('/fundraise');
    // router.pathname === '/appeals/[slug]'
    // router.asPath === '/' ||
    // router.asPath === '/appeals';
    if (!hideHeaderCtaCriteria) {
      setShowHeaderCTA(true);
    }
    return () => {
      setShowHeaderCTA(false);
    };
  }, [router]);

  // const matches = useMediaQuery('(min-width: 992px)');
  // const HEADER_HEIGHT = matches ? 130 : 90;
  const dispatch = useAppDispatch();
  const { data } = useSession();

  return (
    // <Header height={HEADER_HEIGHT} className={classes.root}>
    <Header height={90} className={classes.root}>
      <Container className={classes.header} size="xl">
        <Link href="/">
          <Image src={DesktopLogo} alt="logo" width={100} />
          {/* <DesktopLogo width={matches ? 355 : 177} height={matches ? 55 : 30} /> */}
        </Link>

        <Group spacing={30}>
          {nav &&
            nav?.map((firstLevel: NavigationItem) => (
              <Group position="center" key={firstLevel.id} className={classes.hideOnMobile}>
                <HoverCard>
                  <HoverCard.Target>
                    <UnstyledButton className={classes.primaryLink}>
                      {firstLevel.title}
                    </UnstyledButton>
                  </HoverCard.Target>
                  {firstLevel.items?.length! > 0 && (
                    <HoverCard.Dropdown>
                      {firstLevel.items?.map((item) => (
                        <Box key={item?.id}>
                          <Link href={item?.path!} className={classes.nestedLinks}>
                            {item?.title}
                          </Link>
                        </Box>
                      ))}
                    </HoverCard.Dropdown>
                  )}
                </HoverCard>
              </Group>
            ))}
        </Group>

        <Group spacing={0} position="right" noWrap>
          {/* pathname:- {router.pathname}
          basepath:- {router.basePath}
          route:- {router.route}
          asPath:- {router.asPath} */}
          {showHeaderCTA && (
            <SimpleGrid
              cols={1}
              spacing="xs"
              breakpoints={[
                { minWidth: 'sm', cols: 1 },
                { minWidth: 'md', cols: 2 },
              ]}
            >
              <Button
                onClick={() => {
                  event('navDonateClick', { category: 'Nav clicks', label: 'DONATE' });
                  router.push('/most-needy');
                }}
                ml={5}
                color="yellow"
              >
                DONATE
              </Button>
              <Button
                onClick={() => {
                  event('navZakahClick', { category: 'Nav clicks', label: 'ZAKAH' });
                  router.push('/appeals/zakah');
                }}
                ml={5}
                color="red.9"
              >
                ZAKAH
              </Button>
            </SimpleGrid>
          )}
          <ActionIcon onClick={() => dispatch(setIsSidebarOpen(true))}>
            <MdOutlineShoppingBasket />
          </ActionIcon>
          <Box className={classes.hideOnMobile}>
            <ProfileMenu />
          </Box>
          <Burger
            opened={opened}
            onClick={() => setOpened(true)}
            className={classes.burger}
            size="sm"
          />
        </Group>
        <Drawer
          styles={{
            drawer: { backgroundColor: '#FED007' },
          }}
          position="right"
          opened={opened}
          onClose={() => setOpened(false)}
        >
          {!data && (
            <Box
              py={10}
              px={16}
              onClick={() => {
                setOpened(false);
                router.push('/account/login');
              }}
            >
              <Text>Login</Text>
            </Box>
          )}

          {data && (
            <>
              <Box
                py={10}
                px={16}
                // icon={<MdLogout size={14} />}
                onClick={() => {
                  setOpened(false);
                  showNotification({
                    title: 'Account',
                    message: 'You have been logged out!',
                    color: 'green',
                  });
                  signOut({ callbackUrl: '/' });
                }}
              >
                Log out
              </Box>
              <Box
                py={10}
                px={16}
                // icon={<MdLogout size={14} />}
                onClick={() => {
                  setOpened(false);
                  router.push('/dashboard');
                }}
              >
                My Account
              </Box>
            </>
          )}
          <Accordion
            variant="filled"
            styles={{
              item: {
                backgroundColor: '#FED007',
                borderRadius: 0,
                '&[data-active]': {
                  backgroundColor: '#F5AC13',
                },
              },
            }}
          >
            {nav &&
              nav.map((firstLevel) => (
                <Accordion.Item key={firstLevel.id} value={firstLevel.title}>
                  <Accordion.Control>{firstLevel.title}</Accordion.Control>
                  <Accordion.Panel>
                    {firstLevel.items?.length! > 0 &&
                      firstLevel.items?.map((item) => (
                        <Box key={item?.title}>
                          <Link
                            onClickCapture={() => setOpened(false)}
                            href={item?.path!}
                            className={classes.nestedLinks}
                          >
                            {item?.title}
                          </Link>
                        </Box>
                      ))}
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
          </Accordion>
        </Drawer>
      </Container>
    </Header>
  );
};

export default ResponsiveHeader;
