import { generateId } from '@/utils/helpers';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { EventCheckoutType } from 'types/eventTypes';

const initialState: EventCheckoutType = {
  quantity: 0,
  appealId: '',
  name: '',
  id: generateId(),
  interval: '',
  sfProjectId: '',
  sku: '',
  sfVariationId: '',
  amount: 0,
  attendees: [],
};

const eventCheckoutSlice = createSlice({
  name: 'eventCheckout',
  initialState,
  reducers: {
    updateEventCheckout: (state, action: PayloadAction<EventCheckoutType>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateEventCheckout } = eventCheckoutSlice.actions;

export default eventCheckoutSlice.reducer;
