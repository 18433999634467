import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  footer: {
    background: theme.colors.yellow[5],
    padding: '2rem 0',
  },
  headOffice: {
    color: 'black',
  },
  item: {
    '.mantine-Accordion-control': {
      backgroundColor: 'unset',
      color: 'black',
      '&:hover': {
        backgroundColor: 'unset',
        color: 'black',
      },
    },
    '.mantine-Accordion-item': {
      color: 'black',
      border: 0,
    },
    '.mantine-Accordion-label, .mantine-NavLink-label': {
      fontWeight: 900,
      fontSize: '1.25rem',
    },
  },
  // button.
  hideOnMobile: {
    display: 'block',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  hideOnDesktop: {
    display: 'none',
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },
  addressText: {
    marginLeft: '1rem',
    svg: {
      marginRight: '0.5rem',
    },
  },
}));
