import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TeamPageSlice } from './types';

const initialState: TeamPageSlice = {
  id: '',
  appeal: '',
  teamMemberGoal: 1000,
  teamPageEndDate: '',
  teamPageGoal: 5000,
  teamPageName: '',
  teamPageStory: '',
};

const teamPage = createSlice({
  name: 'teamPage',
  initialState,
  reducers: {
    updateTeamPage: (state, action: PayloadAction<TeamPageSlice>) => {
      return { ...state, ...action.payload };
    },
    resetTeamPage: () => initialState,
  },
});

export const { updateTeamPage, resetTeamPage } = teamPage.actions;

export default teamPage.reducer;
