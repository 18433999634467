import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SUUID } from 'short-uuid';
import { Enum_Componentdonationquickdonateoptions_Frequency } from 'types/cms';
import { generateId, percentageInc, reversePercentage } from '@/utils/helpers';
import { EnumIntention, StripeDonation, StripeDonationState } from './types';

const initialState: StripeDonationState = {
  donations: [],
  sidebar: false,
  giftaid: true,
  hasSadakahUplift: false,
  hasZakahUplift: false,
};

const donationSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    addDonation: (state, action: PayloadAction<StripeDonation>) => {
      const zakatUplift = state.hasZakahUplift
        ? percentageInc(action.payload.amount, 12.5)
        : action.payload.amount;
      state.donations = [
        ...state.donations,
        {
          ...action.payload,
          amount:
            action.payload.intention === EnumIntention.Zakat ? zakatUplift : action.payload.amount,
        },
      ];
    },
    removeDonation: (state, action: PayloadAction<SUUID>) => {
      state.donations = state.donations.filter((donation) => donation.id !== action.payload);
      const zakats =
        state.donations.filter((donation) => donation.intention === EnumIntention.Zakat).length > 0;
      if (!zakats) {
        state.hasZakahUplift = false;
      }
    },
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebar = action.payload;
    },
    resetBasket: () => {
      return initialState;
    },
    updateDonationAmount: (state, action: PayloadAction<{ id: SUUID; amount: number }>) => {
      state.donations = state.donations.map((donation) => {
        if (donation.id === action.payload.id) {
          return { ...donation, amount: action.payload.amount };
        }
        return donation;
      });
    },
    addZakahUplift: (state, action: PayloadAction<boolean>) => {
      state.donations = state.donations.map((donation) => {
        if (donation.intention === EnumIntention.Zakat) {
          const hishab = action.payload
            ? percentageInc(donation.amount, 12.5)
            : reversePercentage(donation.amount, 12.5);
          return { ...donation, amount: hishab };
        }
        return donation;
      });

      state.hasZakahUplift = action.payload;
    },
    toggleCrossSell: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        const donation: StripeDonation = {
          appealId: '',
          name: 'Sadaqah for fees',
          sfProjectId: process.env.NEXT_PUBLIC_DEFAULT_SF_PROJECT_ID!,
          sku: process.env.NEXT_PUBLIC_DEFAULT_SF_PROJECT_ID!,
          sfVariationId: '',
          amount: 5,
          interval: Enum_Componentdonationquickdonateoptions_Frequency.Single,
          id: generateId(),
          quantity: 1,
        };
        state.donations = [...state.donations, donation];
      } else {
        state.donations = state.donations.filter(
          (donation) => donation.name !== 'Sadaqah for fees'
        );
      }
      state.hasSadakahUplift = action.payload;
    },
  },
});

export const {
  resetBasket,
  addZakahUplift,
  removeDonation,
  addDonation,
  toggleCrossSell,
  setIsSidebarOpen,
  updateDonationAmount,
} = donationSlice.actions;

export default donationSlice.reducer;
