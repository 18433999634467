import { Action, combineReducers, ThunkAction, configureStore } from '@reduxjs/toolkit';
// import {
//   FLUSH,
//   PAUSE,
//   PERSIST,
//   persistReducer,
//   persistStore,
//   PURGE,
//   REGISTER,
//   REHYDRATE,
// } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import appealsSlice from './slices/appealsSlice';
import userSlice from './slices/userSlice';
import donationSlice from './slices/donationSlice';
import fundraiserCheckout from './slices/fundraiserCheckout';
import fundraiserSlice from './slices/fundraiserSlice';
import eventCheckoutSlice from './slices/eventCheckoutSlice';
import teamAccountSlice from './slices/teamAccountSlice';
import teamPageSlice from './slices/teamPageSlice';

// const persistConfig = {
//   key: 'root',
//   version: 1,
//   storage,
//   blacklist: ['fundraiserCheckout'],
// };

const rootReducer = combineReducers({
  donation: donationSlice,
  fundraiser: fundraiserSlice,
  appeals: appealsSlice,
  fundraiserCheckout,
  user: userSlice,
  eventCheckoutSlice,
  teamAccount: teamAccountSlice,
  teamPage: teamPageSlice,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  // reducer: persistedReducer,
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }),
});

// export const persistor = persistStore(store);
export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
